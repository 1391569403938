<li
  ngbDropdown
  #dropdown="ngbDropdown"
  class="nav-item dropdown-notification mr-25"
>
  <a
    class="nav-link"
    ngbDropdownToggle
    id="navbarNotificationDropdown"
    (click)="searchUserNotifications()"
    ><i class="ficon" data-feather="bell"></i
    ><span
      class="badge badge-pill badge-danger badge-up"
      *ngIf="currentUser && currentUser.unread_notifications"
      >{{ currentUser.unread_notifications }}</span
    ></a
  >
  <ul
    ngbDropdownMenu
    aria-labelledby="navbarNotificationDropdown"
    class="dropdown-menu dropdown-menu-media dropdown-menu-right"
  >
    <!-- Notifications header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">Notificaciones</h4>
        <div
          class="badge badge-pill badge-light-primary"
          *ngIf="currentUser && currentUser.unread_notifications"
        >
          {{ currentUser.unread_notifications }}
          {{
            currentUser.unread_notifications >= 2 ||
            currentUser.unread_notifications === 0
              ? "Nuevas"
              : "Nueva"
          }}
        </div>
      </div>
    </li>
    <!--/ Notifications header -->

    <!-- Notifications content -->
    <li class="scrollable-container media-list" [perfectScrollbar]>
      <a
        class="d-flex"
        *ngFor="let notification of notifications"
        (click)="openNotification(notification, templateNotification)"
      >
        <div class="media">
          <div class="col-10 d-flex flex-column align-items-start">
            <div class="media-body">
              <p class="media-heading">
                <span class="font-weight-bolder">{{ notification.title }}</span>
              </p>
              <p class="mt-1">{{ notification.message }}</p>
            </div>
            <!-- Button to mark as reading -->
            <div class="d-flex justify-content-between" style="width: 100%">
              <span
                class="text-action"
                [ngClass]="{
                  'text-primary': !notification.read,
                  'text-secondary': notification.read
                }"
                (click)="toggleNotificationState(notification, $event)"
              >
                {{
                  notification.read
                    ? "Marcar como no leída"
                    : "Marcar como leída"
                }}
              </span>
              <span>{{ notification.sent_date | date : "yyyy-MM-dd" }}</span>
            </div>
            <!-- Button to mark as reading -->
          </div>
          <div
            class="d-flex justify-content-center align-items-center col-2"
            style="height: 100%"
          >
            <svg
              *ngIf="!notification.read"
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="10"
              fill="currentColor"
              class="ml-2 text-primary"
              viewBox="0 0 16 16"
              style="padding-bottom: 2px"
            >
              <circle cx="8" cy="8" r="8" />
            </svg>
          </div>
        </div>
      </a>
    </li>
    <!--/ Notifications content -->

    <!--/ See more -->
    <li class="dropdown-menu-footer">
      <a
        class="btn btn-primary btn-block"
        [ngClass]="{ disabled: currentPageNumber === totalPagesNumber }"
        (click)="getNextPage()"
        >Ver más</a
      >
    </li>
    <!--/ See more -->
  </ul>
</li>

<ng-template #templateNotification let-modal>
  <div class="modal-content" *ngIf="currentNotification">
    <div class="modal-header">
      <h4 class="modal-header">{{ currentNotification.title }}</h4>
      <button
        type="button"
        class="close"
        (click)="modal.dismiss()"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p [innerHtml]="safeHtmlMessage"></p>
    </div>
  </div>
</ng-template>
