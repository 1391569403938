import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";
import { SharedService } from "./shared.service";

@Injectable()
export class InterceptorSpinner implements HttpInterceptor {
  constructor(private _shared_service: SharedService) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this._shared_service.show();
    return next.handle(req).pipe(finalize(() => this._shared_service.hide()));
  }
}
