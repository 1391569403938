import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { RouteGuardService } from "./shared/guards/route-guard.service";

const appRoutes: Routes = [
  {
    path: "",
    loadChildren: () =>
      import("./main/public/public.module").then((m) => m.PublicModule),
  },
  {
    path: "patients",
    loadChildren: () =>
      import("./main/patients/patients.module").then((m) => m.PatientsModule),
    canActivate: [RouteGuardService],
  },
  {
    path: "general",
    loadChildren: () =>
      import("./main/general/general.module").then((m) => m.GeneralModule),
    canActivate: [RouteGuardService],
  },
  {
    path: "indicators",
    loadChildren: () =>
      import("./main/indicators/indicators.module").then(
        (m) => m.IndicatorsModule
      ),
    canActivate: [RouteGuardService],
  },
  {
    path: "alerts",
    loadChildren: () =>
      import("./main/alerts/alerts.module").then((m) => m.AlertsModule),
    canActivate: [RouteGuardService],
  },
  {
    path: "variables",
    loadChildren: () =>
      import("./main/variables/variables.module").then(
        (m) => m.VariablesModule
      ),
    canActivate: [RouteGuardService],
  },
  {
    path: "routes",
    loadChildren: () =>
      import("./main/routes/routes.module").then((m) => m.RoutesModule),
    canActivate: [RouteGuardService],
  },
  {
    path: "projections",
    loadChildren: () =>
      import("./main/projections/projections.module").then(
        (m) => m.ProjectionsModule
      ),
    canActivate: [RouteGuardService],
  },
  {
    path: "**",
    redirectTo: "/miscellaneous/error", //Error 404 - Page not found
  },
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: "enabled", // Add options right here
      relativeLinkResolution: "legacy",
    }),
    TranslateModule.forRoot(),
  ],
})
export class AppRoutingModule {}
