import {
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  Input,
} from "@angular/core";

import {
  ApexChart,
  ApexStroke,
  ApexDataLabels,
  ApexXAxis,
  ApexTooltip,
  ApexPlotOptions,
  ApexYAxis,
  ApexFill,
  ApexMarkers,
  ApexNonAxisChartSeries,
  ApexLegend,
  ApexResponsive,
  ApexStates,
} from "ng-apexcharts";

import { CoreConfigService } from "@core/services/config.service";

export interface ChartOptions2 {
  // Apex-non-axis-chart-series
  series?: ApexNonAxisChartSeries;
  chart?: ApexChart;
  stroke?: ApexStroke;
  tooltip?: ApexTooltip;
  dataLabels?: ApexDataLabels;
  fill?: ApexFill;
  colors?: string[];
  legend?: ApexLegend;
  labels?: any;
  plotOptions?: ApexPlotOptions;
  responsive?: ApexResponsive[];
  markers?: ApexMarkers[];
  xaxis?: ApexXAxis;
  yaxis?: ApexYAxis;
  states?: ApexStates;
}

@Component({
  selector: "app-graph-stadistic-indicator",
  templateUrl: "./graph-stadistic-indicator.component.html",
  styleUrls: ["./graph-stadistic-indicator.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class GraphStadisticIndicatorComponent implements OnInit {
  
  // Recibe la información del gráfico desde el componente padre (stadistic-indicators)
  @Input() dataIndicator: any = {};

  // Rastrea las referencias del gráfico 
  @ViewChild("apexDonutChartRef") apexDonutChartRef: any;

  // Tipa la variable del gráfico
  public apexDonutChart: Partial<ChartOptions2>;
  public isMenuToggled = true;

  constructor(private _coreConfigService: CoreConfigService) {}

  ngOnChanges() {
 
    // Proceso el input para agregar la información al gráfico
    const series = this.dataIndicator.options.map(item => Number(item.value));
    const colors = this.dataIndicator.options.map(item => item.color);
    const labels = this.dataIndicator.options.map(item => item.label);

    // Configuraciones y valores del gráfico
    this.apexDonutChart = {
      series: series,
      chart: {
        height: 500,
        type: "donut",
      },
      colors: colors,
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              name: {
                fontSize: "2rem",
                fontFamily: "Montserrat",
              },
              value: {
                fontSize: "1rem",
                fontFamily: "Montserrat",
                formatter: function (val) {
                  return val;
                },
              },
            },
          },
        },
      },
      legend: {
        show: true,
        position: "bottom",
        horizontalAlign: "left",
      },
      labels: labels,
      responsive: [
        {
          breakpoint: 1600,
          options: {
            chart: {
              height: 500,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    };
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    // Subscribe to core config changes
    this._coreConfigService.getConfig().subscribe((config) => {
      // If Menu Collapsed Changes
      if (
        config.layout.menu.collapsed === true ||
        config.layout.menu.collapsed === false
      ) {
        setTimeout(() => {
          // Get Dynamic Width for Charts
          this.isMenuToggled = true;
          this.apexDonutChart.chart.width =
            this.apexDonutChartRef?.nativeElement.offsetWidth;
        }, 200);
      }
    });
  }
}
