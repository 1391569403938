<!-- Footer -->
<p class="clearfix mb-0 px-5">
  <span class="float-md-left d-block d-md-inline-block mt-25">
    COPYRIGHT &copy; {{ year }}
    <a class="ml-25" href="https://www.s4l.life/" target="_blank">S4L</a>
    <span class="d-none d-sm-inline-block"
      >, Todos los derechos reservados</span
    >
  </span>
  <span
    class="float-md-right d-none d-md-block"
    (click)="redirectToIg()"
    style="cursor: pointer"
  >
    Hecho en Colombia <span [data-feather]="'heart'" [class]="'pink'"></span>
  </span>
</p>
<!--/ Footer -->

<!-- Move to top Button-->
<app-scroll-top *ngIf="coreConfig.layout.scrollTop"></app-scroll-top>

<!-- Buynow Button-->
<div class="buy-now">
  <a
    href="https://1.envato.market/vuexy_admin"
    target="_blank"
    class="btn btn-danger"
    *ngIf="coreConfig.layout.buyNow"
    >Buy Now</a
  >
</div>
