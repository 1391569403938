import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { AuthenticationService } from "app/auth/service";

@Injectable()
export class InterceptorJwt implements HttpInterceptor {
  constructor(private _authService: AuthenticationService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this._authService.getJWT();

    const encodedUrl = encodeURI(request.url);
    request = request.clone({ url: encodedUrl });

    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    } else {
      return next.handle(request);
    }

    return next.handle(request).pipe(
      catchError((error) => {
        if (error.status === 401) {
          this._authService.signOut();
        }
        return throwError(error);
      })
    );
  }
}
