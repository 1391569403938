import { CoreMenu } from "@core/types";

export const menu: CoreMenu[] = [
  // SECCIÓN PRINCIPAL
  {
    id: "main-section",
    type: "section",
    title: "Página principal",
    icon: "package",
    children: [
      {
        id: "start",
        title: "Inicio",
        type: "item",
        icon: "home",
        url: "general/home",
      },
    ],
  },

  //SECCIÓN DE GESTIÓN
  {
    id: "gestion-section",
    type: "section",
    title: "Gestión",
    icon: "package",
    children: [
      {
        id: "patients",
        title: "Pacientes",
        type: "collapsible",
        icon: "users",
        children: [
          {
            id: "management_patient",
            title: "Gestionar",
            type: "item",
            icon: "book-open",
            url: "patients/manage-patient",
            permissions: {
              patient: ["get", "put"],
              patient_route: ["get", "post", "put"],
              shared: ["get"],
              patient_medical_order: ["get", "post", "put"],
              alert: ["get"],
            },
          },
          {
            id: "create_patient",
            title: "Crear",
            type: "item",
            icon: "plus",
            url: "patients/create-patient",
            permissions: { patient: ["get", "post"], shared: ["get"] },
          },
          {
            id: "list_patients",
            title: "Listar",
            type: "item",
            icon: "list",
            url: "patients/list-patients",
            permissions: { route: ["get"], shared: ["get"] },
          },
        ],
      },
      {
        id: "projections",
        title: "Planes de cuidado",
        type: "item",
        icon: "activity",
        url: "projections/projections",
      },
      {
        id: "alerts",
        title: "Alertas",
        type: "item",
        icon: "alert-triangle",
        url: "alerts/alerts",
        permissions: { route: ["get"], alert: ["get"] },
      },
    ],
  },

  //SECCIÓN DE PARAMETRIZACIÓN
  {
    id: "parametrization-section",
    type: "section",
    title: "Parametrización",
    role: ["Gestor"],
    children: [
      {
        id: "parametrization-routes",
        title: "Crear ruta",
        type: "item",
        icon: "plus-square",
        url: "routes/create-route",
        permissions: { route: ["get", "post"], shared: ["get"] },
      },
      {
        id: "parametrization-variables",
        title: "Crear variable",
        type: "item",
        icon: "plus",
        url: "variables/create-variable",
        permissions: {
          route: ["get"],
          variable: ["get", "post"],
          shared: ["get"],
        },
      },
    ],
  },

  //SECCIÓN DE DATA
  {
    id: "parametrization-section",
    type: "section",
    title: "Data",
    role: ["Gestor"],
    children: [
      {
        id: "report",
        title: "Reportes",
        type: "item",
        icon: "download",
        url: "general/report",
        permissions: {
          route: ["get"],
          report: ["get"],
        },
      },
      {
        id: "indicators",
        title: "Indicadores",
        type: "item",
        icon: "clipboard",
        url: "indicators/powerbi-indicators",
        permissions: {
          indicator: ["get"],
        },
      },
    ],
  },
];
