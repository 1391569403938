<div class="col-12">
  <div class="card">
    <div class="card-header">
      <div class="min-heigth-text">
        <h4 class="card-title mb-75"> {{ dataIndicator.name_indicator }} </h4>
        <span class="card-subtitle text-muted"> {{ dataIndicator.description_indicator }} </span>
      </div>

    </div>
    <div class="card-body">
      <div id="donut-chart" #apexDonutChartRef>
        <apx-chart
          [series]="apexDonutChart.series"
          [chart]="apexDonutChart.chart"
          [labels]="apexDonutChart.labels"
          [plotOptions]="apexDonutChart.plotOptions"
          [responsive]="apexDonutChart.responsive"
          [colors]="apexDonutChart.colors"
          [legend]="apexDonutChart.legend"
        ></apx-chart>
      </div>
    </div>
  </div>