import { Component, OnInit } from "@angular/core";
import { SharedService } from "app/shared/services/shared.service";

@Component({
  selector: "app-load-spinner",
  templateUrl: "./load-spinner.component.html",
  styleUrls: ["./load-spinner.component.scss"],
})
export class LoadSpinnerComponent implements OnInit {
  isLoading$ = this._interceptorSpinner.isLoading$;
  constructor(private _interceptorSpinner: SharedService) {}

  ngOnInit(): void {}

  isDark() {
    const config = localStorage.getItem("config");
    if (config) {
      const configObject = JSON.parse(config);
      return configObject.layout.skin === "dark";
    }
    return false;
  }
}
