import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";

import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { CoreMenuService } from "@core/components/core-menu/core-menu.service";

@Component({
  selector: "[core-menu]",
  templateUrl: "./core-menu.component.html",
  styleUrls: ["./core-menu.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoreMenuComponent implements OnInit {
  currentUser: any;
  permissionsUser: any;

  @Input()
  layout = "vertical";

  @Input()
  menu: any;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   *
   * @param {ChangeDetectorRef} _changeDetectorRef
   * @param {CoreMenuService} _coreMenuService
   */
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _coreMenuService: CoreMenuService
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
    const userData = JSON.parse(localStorage.getItem("current_user"));
    if (userData) {
      this.permissionsUser = userData.permissions;
    }
  }

  // Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Set the menu either from the input or from the service
    this.menu = this.menu || this._coreMenuService.getCurrentMenu();

    this.processMenu();

    // Subscribe to the current menu changes
    this._coreMenuService.onMenuChanged
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        this.currentUser = this._coreMenuService.currentUser;

        // Load menu
        this.menu = this._coreMenuService.getCurrentMenu();

        this._changeDetectorRef.markForCheck();
      });
  }

  private checkPermissions(item: any): void {
    if (!item.permissions || !this.permissionsUser?.deny) return;

    for (const permission in item.permissions) {
      if (permission in this.permissionsUser.deny) {
        const denyPatientsSet = new Set(this.permissionsUser.deny[permission]);
        const isDenied = item.permissions[permission].some((perm) =>
          denyPatientsSet.has(perm)
        );

        item.hidden = isDenied;

        if (item.hidden) return;
      }
    }
  }

  private processMenuItems(items: any[]): void {
    for (const item of items) {
      this.checkPermissions(item);
      if (item.children) {
        this.processMenuItems(item.children);
      }
    }
  }

  private processMenu(): void {
    for (const section of this.menu) {
      if (section.children) {
        this.processMenuItems(section.children);
      }
    }
  }
}
