<div class="col-12">
  <div class="card">
    <div class="card-header d-flex text-center min-height-text">
      <h4 class="col-12 text-center card-title mb-75">
        {{ dataIndicator.name_indicator }}
      </h4>
      <span class="col-12 text-center card-subtitle text-muted py-2"
        >
        {{ dataIndicator.description_indicator }}
        </span
      >
    </div>
    <div class="card-body">
      <div id="donut-chart" #goalChartRef>
        <apx-chart
          [chart]="goalChartoptions.chart"
          [plotOptions]="goalChartoptions.plotOptions"
          [fill]="goalChartoptions.fill"
          [colors]="goalChartoptions.colors"
          [series]="goalChartoptions.series"
          [stroke]="goalChartoptions.stroke"
          [grid]="goalChartoptions.grid"
        ></apx-chart>
      </div>
      <div class="row border-top text-center mx-0">
        <div class="col-6 border-right py-1">
          <p class="card-text text-muted mb-0">Total</p>
          <h3 class="font-weight-bolder mb-0">0</h3>
        </div>
        <div class="col-6 py-1">
          <p class="card-text text-muted mb-0">Sin dato</p>
          <h3 class="font-weight-bolder mb-0">0</h3>
        </div>
        <div class="col-6 border-top border-right py-1">
          <p class="card-text text-muted mb-0">Cumple</p>
          <h3 class="font-weight-bolder mb-0">262</h3>
        </div>
        <div class="col-6 border-top py-1">
          <p class="card-text text-muted mb-0">No Cumple</p>
          <h3 class="font-weight-bolder mb-0">20</h3>
        </div>
      </div>
    </div>
  </div>
</div>