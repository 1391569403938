import { Component, OnInit, Input } from "@angular/core";
import { HeaderStep } from "app/shared/models/stepper";
@Component({
  selector: "app-stepper-header",
  templateUrl: "./stepper-header.component.html",
})
export class StepperHeaderComponent {
  @Input() headerSteps: HeaderStep[] = [];
  @Input() title: string = "";
}
