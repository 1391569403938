<div class="d-flex justify-content-between mt-2">
    <button
      class="btn btn-outline-secondary btn-prev"
      [disabled]="disablePrevious"
      (click)="onPreviousClick()"
      rippleEffect
    >
      <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
      <span class="align-middle d-sm-inline-block d-none">Anterior</span>
    </button>
    <button
      [type]="submitButton ? 'submit' : 'button'"
      class="btn btn-primary btn-next"
      (click)="onNextClick($event)"
      rippleEffect
    >
      <span class="align-middle d-sm-inline-block d-none">{{ nextButtonText }}</span>
      <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
    </button>
  </div>