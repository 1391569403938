import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "environments/environment";

@Injectable({
  providedIn: "root",
})
export class LayoutService {
  private apiUrl = environment.baseUrl;

  constructor(private http: HttpClient) {}

  searchUserNotifications(page: number): Observable<any> {
    return this.http.get(`${this.apiUrl}/notifications?page=${page}`);
  }

  updateNotificationState(notification_id: number, data): Observable<any> {
    return this.http.put(
      `${this.apiUrl}/notifications/${notification_id}`,
      data
    );
  }

  searchNotificationById(notification_id: number): Observable<any> {
    return this.http.get(`${this.apiUrl}/notifications/${notification_id}`);
  }
}
