import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgApexchartsModule } from "ng-apexcharts";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";

import { CoreCommonModule } from "@core/common.module";

//Componentes compartidos declarados
import { GraphStadisticIndicatorComponent } from "./components/graph-stadistic-indicator/graph-stadistic-indicator.component";
import { GraphOpportunityIndicatorComponent } from "./components/graph-opportunity-indicator/graph-opportunity-indicator.component";
import { GraphProportionIndicatorComponent } from "./components/graph-proportion-indicator/graph-proportion-indicator.component";
import { LoadSpinnerComponent } from "./components/load-spinner/load-spinner.component";
import { StepperHeaderComponent } from './components/stepper-header/stepper-header.component';
import { StepperButtonsComponent } from "./components/stepper-buttons/stepper-buttons.component";

//Pipes compartidos
import { FilterOpontunityIndicator } from "./pipes/filter-opportunity-indicator.pipe";
import { FilterVariablesState } from "./pipes/filter-variables-state.pipe";
import { CoreCardModule } from "@core/components/core-card/core-card.module";

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    CoreCommonModule,
    NgApexchartsModule,
    CoreCardModule,
  ],
  declarations: [
    // Componentes
    GraphStadisticIndicatorComponent,
    GraphOpportunityIndicatorComponent,
    GraphProportionIndicatorComponent,
    LoadSpinnerComponent,
    StepperHeaderComponent,
    StepperButtonsComponent,
    // Pipes
    FilterOpontunityIndicator,
    FilterVariablesState,
  ],
  exports: [
    // Componentes
    GraphStadisticIndicatorComponent,
    GraphOpportunityIndicatorComponent,
    GraphProportionIndicatorComponent,
    CoreCardModule,
    LoadSpinnerComponent,
    StepperHeaderComponent,
    StepperButtonsComponent,
    // Modules
    NgxDatatableModule,
    // Pipes
    FilterOpontunityIndicator,
    FilterVariablesState,
  ],
})
export class SharedModule {}
