import { Component, OnInit, ViewChild, TemplateRef } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgbDropdown } from "@ng-bootstrap/ng-bootstrap";
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { LayoutService } from "app/layout/layout.service";

import { currentUser } from "app/shared/models/currentUser";

// Interface
interface notification {
  identifier: number;
  title: number;
  message: string;
  created_at?: string;
  sent_date: string;
  read: string;
  user_id?: string;
}

@Component({
  selector: "app-navbar-notification",
  templateUrl: "./navbar-notification.component.html",
  styleUrls: ["./navbar-notification.component.scss"],
})
export class NavbarNotificationComponent implements OnInit {
  @ViewChild("templateNotification", { static: true })
  templateNotification: TemplateRef<any>;

  @ViewChild("dropdown") dropdown: NgbDropdown;

  protected currentUser: currentUser;
  protected currentNotification: notification;
  private openedNotifications: boolean = false;
  protected currentPageNumber: number = 1;
  protected totalPagesNumber: number = 1;
  safeHtmlMessage: SafeHtml; 

  public notifications: notification[] = [];

  constructor(
    private _layoutService: LayoutService,
    private _modalService: NgbModal,
    private sanitizer: DomSanitizer
  ) {
    this.currentUser = JSON.parse(localStorage.getItem("current_user"));
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.openedNotifications = this.dropdown.isOpen();
  }

  searchUserNotifications(
    showMoreButtonClicked: boolean = false,
    page_number: number = 1
  ) {
    this.toggleNotifications();
    this.currentPageNumber = page_number;
    if (this.openedNotifications) {
      this._layoutService
        .searchUserNotifications(page_number)
        .subscribe((response) => {
          showMoreButtonClicked === true
            ? this.notifications.push(...response["notifications"])
            : (this.notifications = response["notifications"]);

          this.totalPagesNumber = response["total_pages"];
          this.currentUser.unread_notifications = response.unread_notifications;
          localStorage.setItem(
            "current_user",
            JSON.stringify(this.currentUser)
          );
        });
    }
  }

  openNotification(notification: notification, modalForm) {
    this._layoutService
      .searchNotificationById(notification.identifier)
      .subscribe((response) => {
        this.currentNotification = response;
        if (this.currentNotification) {
          this.safeHtmlMessage = this.sanitizer.bypassSecurityTrustHtml(this.currentNotification.message); 
          this._modalService.open(modalForm, {
            centered: true,
            size: "lg",
          });

          this.toggleNotifications();
          this.dropdown.close();
        }

        if (response.read == false) {
          this.toggleNotificationState(notification);
        }
      });
  }

  toggleNotifications() {
    this.openedNotifications = this.dropdown.isOpen();
  }

  toggleNotificationState(notification, event?: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }

    const dataRequest = {
      read: !notification.read,
    };

    this._layoutService
      .updateNotificationState(notification.identifier, dataRequest)
      .subscribe((response) => {
        notification.read = response.read;
        this.currentUser.unread_notifications += !response.read ? 1 : -1;

        localStorage.setItem("current_user", JSON.stringify(this.currentUser));
      });
  }

  getNextPage() {
    if (this.currentPageNumber < this.totalPagesNumber) {
      this.currentPageNumber++;
      this.searchUserNotifications(true, this.currentPageNumber);
    }
  }
}
