<div class="overlay" *ngIf="isLoading$ | async">
  <div
    [ngClass]="{ 'loading-bg': !isDark(), 'dark-loading-bg': isDark() }"
  >
    <div class="loading-logo">
      <img src="assets/images/logo/logo_spinner.png" alt="Logo" />
    </div>
    <div class="loading">
      <div class="effect-1 effects"></div>
      <div class="effect-2 effects"></div>
      <div class="effect-3 effects"></div>
    </div>
  </div>
</div>
