import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-stepper-buttons',
  templateUrl: './stepper-buttons.component.html',
  styleUrls: []
})
export class StepperButtonsComponent {
  @Input() disablePrevious: boolean = false;
  @Input() nextButtonText: string = 'Siguiente';
  @Input() submitButton: boolean = true;

  @Output() previousClick = new EventEmitter<void>();
  @Output() nextClick = new EventEmitter<any>();

  onPreviousClick() {
    this.previousClick.emit();
  }

  onNextClick(param: any) {
    this.nextClick.emit(param);
  }
}