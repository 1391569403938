import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  CanActivate,
} from "@angular/router";
import { AuthenticationService } from "app/auth/service";

@Injectable({
  providedIn: "root",
})
export class RouteGuardService implements CanActivate {
  constructor(
    private authService: AuthenticationService,
    private router: Router
  ) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    try {
      const token = localStorage.getItem("token");
      if (token) {
        return true;
      } else {
        this.authService.signOut();
        this.router.navigate([""]);
        return false;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }
}
