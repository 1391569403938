import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { environment } from "environments/environment";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  private apiUrl = environment.baseUrl;

  constructor(private http: HttpClient, private _router: Router) {}

  login(data: object): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/login`, data);
  }

  confirmMFA(data: object): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/validate-mfa`, data);
  }

  confirmTOTP(data: object): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/validate-otp`, data);
  }

  getDataUser(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/user`);
  }

  signOut() {
    localStorage.removeItem("token");
    localStorage.removeItem("current_user");
  }

  getJWT() {
    try {
      const token = localStorage.getItem("token");
      return token;
    } catch (error) {
      return null;
    }
  }

  getUserData() {
    return JSON.parse(localStorage.getItem("current_user"));
  }
}
