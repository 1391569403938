<div class="bs-stepper-header container col-12 justify-content-center">
  <h1 class="col-12 text-center text-lg-left px-0 mb-2">
    {{ title }}
  </h1>
  <!-- Stepper's steps -->
  <div
    class="step"
    *ngFor="let step of headerSteps; let i = index"
    [attr.data-target]="step.ref"
  >
    <button class="step-trigger">
      <span class="bs-stepper-box">{{ i + 1 }}</span>
      <span class="bs-stepper-label mt-0">
        <span class="bs-stepper-title">{{ step.title }}</span>
      </span>
      <div class="line" *ngIf="i < headerSteps.length - 1">
        <i data-feather="chevron-right" class="font-medium-2"></i>
      </div>
    </button>
  </div>
</div>
