import { Injectable, Renderer2, RendererFactory2 } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "environments/environment";
import { Subject } from "rxjs";
import Stepper from "bs-stepper";
import { routeGroup } from "../models/route";

@Injectable({
  providedIn: "root",
})
export class SharedService {
  private apiUrl = environment.baseUrl;
  private renderer: Renderer2;
  isLoading$ = new Subject<boolean>();
  private requestCount = 0;

  constructor(private http: HttpClient, rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  private updateSpinnerState() {
    if (this.requestCount > 0) {
      this.renderer.addClass(document.body, "no-scroll");
      this.isLoading$.next(true);
    } else {
      this.renderer.removeClass(document.body, "no-scroll");
      this.isLoading$.next(false);
    }
  }

  show(): void {
    this.requestCount++;
    this.updateSpinnerState();
  }

  hide(): void {
    if (this.requestCount > 0) {
      this.requestCount--;
      this.updateSpinnerState();
    }
  }

  // Reusable functions
  
  formatVariablesValues(variables, container = null) {
    variables.map((variable) => {
      if (variable.variable_value && variable.multiple_choice) {
        variable.variableValuesArray = variable.variable_value.split("|");
      }
      return variable;
    });

    if (container) {
      container.variables = variables;
    }
  }

  // Mark step from a stepper component as completed
  markStepAsCompleted(stepIndex: number) {
    const stepElement =
      document.querySelectorAll(".bs-stepper .step")[stepIndex];
    stepElement.classList.add("completed");
  }

  // Unmark step from a stepper component as completed
  unmarkStepAsCompleted(stepIndex: number) {
    const fromStepElement =
      document.querySelectorAll(".bs-stepper .step")[stepIndex];
    const toStepElement =
      document.querySelectorAll(".bs-stepper .step")[stepIndex - 1];
    fromStepElement.classList.remove("completed");
    toStepElement.classList.remove("completed");
  }

  // Format stepper info values to show "SIN DATO" if value is empty
  formatStepperInfoValues(StepperInfo: Record<string, any>) {
    Object.entries(StepperInfo).forEach(([key, value]) => {
      if (typeof value === "object" && "value" in value && value.value === "") {
        value.value = "SIN DATO";
      }
    });
  }

  // Reset stepper to first step and remove completed class from all steps
  resetStepper(stepper: Stepper) {
    const steps = document.querySelectorAll(".bs-stepper .step");
    steps.forEach((step) => step.classList.remove("completed"));

    stepper.to(0);
  }

  // Shared requests

  getUsuario(userId: string): Observable<any> {
    console.log("entra");
    return this.http.get(`${this.apiUrl}/usuarios/${userId}`);
  }

  getEpss(country: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/eps?country=${country}`);
  }

  getDocumentTypes(country: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/document_type?country=${country}`);
  }

  listPopulationGroups(): Observable<any> {
    return this.http.get(`${this.apiUrl}/population_groups`);
  }

  listEthnicities(): Observable<any> {
    return this.http.get(`${this.apiUrl}/ethnicities`);
  }

  listCountries(): Observable<any> {
    return this.http.get(`${this.apiUrl}/countries`);
  }

  listBusinessRoutes(business_id): Observable<any> {
    return this.http.get(`${this.apiUrl}/business/${business_id}/routes`);
  }

  getPatientsRoute(
    route_id: number,
    bring_variable_values: number,
    page: number = 1,
    filters: string = ""
  ): Observable<any> {
    return this.http.get<any>(
      `${this.apiUrl}/routes/${route_id}/patients?bring_variable_values=${bring_variable_values}&page=${page}${filters}`
    );
  }

  getVariablesTypes(): Observable<any> {
    return this.http.get(`${this.apiUrl}/variable_types`);
  }

  getStages(route_id: number): Observable<any> {
    return this.http.get(`${this.apiUrl}/route/${route_id}/stages`);
  }

  createVariableCategory(data): Observable<any> {
    return this.http.post(`${this.apiUrl}/variables/category`, data);
  }

  getVariableCategories(): Observable<any> {
    return this.http.get(`${this.apiUrl}/variables/category`);
  }

  createVariable(data): Observable<any> {
    return this.http.post(`${this.apiUrl}/variables/variable`, data);
  }

  getRouteGroups(): Observable<any> {
    return this.http.get(`${this.apiUrl}/routes/route_group`);
  }

  createRouteGroup(data: routeGroup): Observable<any> {
    return this.http.post(`${this.apiUrl}/routes/route_group`, data);
  }

  createRoute(data, business_id:number): Observable<any> {
    return this.http.post(`${this.apiUrl}/business/${business_id}/routes`, data);
  }

  createStage(data, route_id:number): Observable<any> {
    return this.http.post(`${this.apiUrl}/route/${route_id}/stages`, data);
  }

  getIndicators(): Observable<any> {
    return this.http.get(`${this.apiUrl}/indicators`);
  }

  getIndicatorInfo(indicator_id:number): Observable<any> {
    return this.http.get(`${this.apiUrl}/indicators/${indicator_id}`);
  }

}
